<script setup>
	import { computed } from 'vue';
	import { DsText } from '@coloplast/design-system';
	import BenefitChallengeBlockItem from './components/BenefitChallengeBlockItem.vue';

	const props = defineProps({
		vm: {
			type: Object,
			default: () => {},
		},
	});

	const isImageLayout = computed(() => {
		return props.vm.BaseViewModel.Cards.some(item => item.ImageUrl);
	});
</script>

<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template>
	<div class="c-benefit-challenge__container">
		<DsText
			v-if="vm.CurrentBlock.Headline"
			html-element="h3"
			text-type="heading-3xl"
			class="c-benefit-challenge__headline"
		>
			{{ vm.CurrentBlock.Headline }}
		</DsText>
		<DsText
			v-if="vm.CurrentBlock.Description"
			class="c-benefit-challenge__description"
		>
			<span v-html="vm.CurrentBlock.Description"></span>
		</DsText>
		<div
			v-if="vm.BaseViewModel.Cards.length"
			class="c-benefit-challenge__item-wrapper"
			:class="{ 'c-benefit-challenge__item-wrapper--no-images' : !isImageLayout }"
		>
			<BenefitChallengeBlockItem
				v-for="(card, index) in vm.BaseViewModel.Cards"
				:key="`${card.Title}-${index + 1}`"
				:item="card"
				:items-length="vm.BaseViewModel.Cards.length"
			/>
		</div>
	</div>
</template>
