<!-- eslint-disable @typescript-eslint/no-unused-vars -->
<script setup>
	import { computed } from 'vue';
	import { DsText, dsUseIsMobile } from '@coloplast/design-system';
	import ContentIconText from 'shared/components/contentLibrary/contentIconText.vue';

	const props = defineProps({
		item: {
			type: Object,
			default: () => {},
		},
		itemsLength: {
			type: Number,
			required: true,
		},
	});

	const { isMobile } = dsUseIsMobile();

	const dynamicSize = computed(() => (isMobile?.value ? 'xs' : 'sm'));
</script>

<template>
	<article
		v-if="Object.keys(item).length"
		class="c-benefit-challenge__item"
		:class="{ 'c-benefit-challenge__item--two-column': itemsLength === 2 }"
	>
		<picture v-if="item.ImageUrl">
			<source
				media="(max-width: 767px)"
				:srcset="`${item.ImageUrl}?im=Resize=(327,200)`"
			/>
			<source
				v-if="itemsLength === 3"
				media="(min-width: 768px)"
				:srcset="`${item.ImageUrl}?im=Resize=(408,280)`"
			/>
			<img
				:src="`${item.ImageUrl}?im=Resize=(624,280)`"
				:alt="item.ImageAltText || item.Title"
				class="c-benefit-challenge__item-image"
			/>
		</picture>
		<DsText
			v-if="item.Title"
			html-element="h3"
			text-type="heading-2xl"
			class="c-benefit-challenge__item-headline"
		>
			{{ item.Title }}
		</DsText>
		<DsText
			v-if="item.Text"
			class="c-benefit-challenge__item-description"
		>
			{{ item.Text }}
		</DsText>
		<div
			v-if="item.Attributes?.length"
			class="c-benefit-challenge__item-feature-wrapper"
		>
			<div
				v-for="(feature, index) in item.Attributes"
				:key="`${feature.Text}-${index + 1}`"
				class="c-benefit-challenge__item-feature"
			>
				<ContentIconText
					:icon="feature.Icon"
					:size="dynamicSize"
				>
					{{ feature.Text }}
				</ContentIconText>
			</div>
		</div>
	</article>
</template>
