import { createApp } from 'vue';
import BenefitChallengeBlock from './BenefitChallengeBlock.vue';
import './c-benefit-challenge.scss';

const benefitChallengeBlocks = [...document.querySelectorAll('[data-benefit-challenge]')];

if (benefitChallengeBlocks) {
	benefitChallengeBlocks.forEach((item) => {
		const app = createApp({
			name: 'BenefitChallengeBlockApp',
		});
		app.component('benefit-challenge-block', BenefitChallengeBlock);
		app.mount(item);
	});
}